
	melon.templates['visual_plant_floor_template'] = `{% $.each(workstations, (idx, row) => { %}
	<div class="workstation-wrapper">
		<div class="workstation-status text-right">
			{% if(row.status == "Production") { %}
				<div class="ring-container">
					<div class="ringring"></div>
					<div class="circle"></div>
				</div>
			{% } %}
			<span class="indicator-pill no-indicator-dot whitespace-nowrap {{row.color}}" style="margin: 3px 4px 0px 0px;">
				<span style="font-size:13px">{{row.status}}</span>
			</span>
		</div>
		<div class="workstation-image">
			<div class="flex items-center justify-center h-32 border-b-grey text-6xl text-grey-100">
				<a class="workstation-image-link" href="{{row.workstation_link}}">
					{% if(row.status_image) { %}
						<img class="workstation-image-cls" src="{{row.status_image}}">
					{% } else { %}
						<div class="workstation-image-cls workstation-abbr">{{melon.get_abbr(row.name, 2)}}</div>
					{% } %}
				</a>
			</div>
		</div>
		<div class="workstation-card" style="display: grid;">
			<span class="ellipsis" title="{{row.name}}">
				{{row.workstation_name}}
			</span>
		</div>
	</div>
{% }); %}
`;
