
	melon.templates['item_selector'] = `<div class="app-listing item-list image-view-container item-selector">
{% for (var i=0; i < data.length; i++) { var item = data[i]; %}
	{% if (i % 4 === 0) { %}<div class="image-view-row">{% } %}
	<div class="image-view-item" data-name="{{ item.name }}">
		<div class="image-view-header doclist-row">
			<div class="list-value">
				<a class="grey list-id" data-name="{{item.name}}"
					title="{{ item.item_name || item.name}}">
					{{item.item_name || item.name}}</a>
			</div>
		</div>
		<div class="image-view-body">
			<a  data-item-code="{{ item.name }}"
				title="{{ item.item_name || item.name }}"
			>
				<div class="image-field"
					style="
					{% if (!item.image) { %}
						background-color: #fafbfc;
					{% } %}
					border: 0px;"
				>
					{% if (!item.image) { %}
					<span class="placeholder-text">
						{%= melon.get_abbr(item.item_name || item.name) %}
					</span>
					{% } %}
					{% if (item.image) { %}
					<img src="{{ item.image }}" alt="{{item.item_name || item.name}}">
					{% } %}
				</div>
			</a>
		</div>
	</div>
	{% if ((i % 4 === 3) || (i===data.length - 1)) { %}</div>{% } %}
{% endfor %}
</div>
`;
